.App {
  text-align: center;
  background-color: #fef1e5;
}

body {
  font-size: large;
  background-color: #fef1e5;
  cursor: none;
}

.card-body:hover {
  box-shadow: -10px 10px 0px -2px black;
  transform: translate(5px, -1px);
  transition: all 0.2s ease-in-out;
}

.button-body:hover {
  box-shadow: -5px 5px 0px -1px black;
  transform: translate(5px, -1px);
  transition: all 0.2s ease-in-out;
  font-weight: bold;
}

.special:hover {
  border: 2px solid black;
  background-color: #f2f2f2;
  box-shadow: -5px 5px 0px -1px black;
  transform: translate(5px, -1px);
  transition: all 0.2s ease-in-out;
}

.special-link:hover {
  font-weight: bold; /* Add this line to transition the text to bold */
  color: #ffd301; /* Add this line to change the text color to yellow */
  text-decoration: underline;
  transition: all 0.2s ease-in-out;
}

/* make a class for special when clicked similar to its hover state */

@tailwind base;
@tailwind components;
@tailwind utilities;
